<template>
  <div>
    <section>
      <p>{{ rec }}</p>
      <!-- :headers="getHeaders"
      hide-upload-btn
      @finish="
        $emit('isDirty');
        $emit('close');
      "
       -->

      <q-dialog v-model="showEditor">
        <q-card style="min-width: 90%">
          <q-bar>
            <q-icon name="fa fa-plus" />&nbsp;<span
              style="text-transform: capitalize;"
              >{{ mode }}</span
            >&nbsp;a Viewpoint Collection
            <q-space />
            <q-btn
              round
              flat
              icon="fal fa-times"
              v-close-popup
              class="float-right"
            />
          </q-bar>
          <q-card-section>
            <div class="row">
              <div class="col-4">
                <!--
              <q-img src="/static/placeholder.png" width="240px" height="180px">
                <div class="absolute-bottom text-body1 text-center">
                  <q-btn dense @click="uploadImage" flat label="Replace" /></div
              ></q-img>-->
                <p class="text-h6 q-pa-xs">Iconic image for collection</p>
                <q-img
                  v-if="rec.thumb"
                  :src="rec.thumb"
                  style="max-height:300px"
                  height="200px"
                  contain
                />
                <q-uploader
                  batch
                  accept="image/*"
                  v-show="true"
                  ref="uploadIconicImage"
                  url="/api/upload"
                  :multiple="false"
                  :headers="getHeaders"
                  hide-upload-btn
                  @uploaded="processUpload"
                  ><template v-slot:header="scope">
                    <p class="q-pa-xs" v-if="rec.thumb"><b>Replace image</b></p>
                    <p v-else>Add image</p>
                    <q-btn
                      v-if="scope.canAddFiles"
                      type="a"
                      icon="add_box"
                      label="select file"
                      dense
                      flat
                    >
                      <q-uploader-add-trigger />
                      <q-tooltip>Pick Files</q-tooltip>
                    </q-btn>
                  </template>
                </q-uploader>
              </div>

              <div class="col-8">
                <q-input type="text" v-model="rec.title" label="Title" />
                <q-input type="text" v-model="rec.subtitle" label="Subtitle" />
                <q-input
                  type="textarea"
                  v-model="rec.introduction"
                  label="Introduction"
                />
                <q-input
                  type="textarea"
                  rows="3"
                  v-model="rec.acknowledgements"
                  label="Acknowledgements"
                />
              </div>
            </div>
          </q-card-section>
          <q-card-actions class="flex-center">
            <q-btn no-caps label="Cancel" v-close-popup />
            <q-btn @click="create_or_edit" no-caps label="Ok" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <q-btn
        icon="fa fa-plus"
        label="Create"
        @click="
          showEditor = !showEditor;
          mode = 'create';
        "
      />
    </section>

    <section id="card-holder">
      <div class="row q-mx-auto justify-start items-start" id="collections">
        <div class="row q-gutter-md">
          <div :key="i" v-for="(card, i) in cards" class="column">
            <Card
              :item="card"
              :index="i"
              :uuid="card.uuid"
              :thumb="card.thumb"
              type="Viewpoints Collection"
              :csstype="
                card.is_published ? 'collection published' : 'collection'
              "
              :title="card.title"
              :btn-edit-modal="true"
              :btn-delete="!card.is_published"
              @showEdit="editCollection"
              @deleteCard="deleteCollection(card)"
              :hover="false"
              >{{ card.introduction }}</Card
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { API } from '../tic';
import { mapGetters } from 'vuex';
import Card from '../tags/Card';

// index of a HC by Type
export default {
  name: 'HCbyType',
  components: { Card },

  props: {
    type: {
      type: String,
      default: ''
    }
  },

  mounted() {
    this.getData();
  },

  computed: {
    ...mapGetters('user', ['uuid'])
  },

  methods: {
    editCollection(e) {
      this.mode = 'edit';
      console.log('edit ', e);
      this.rec = { ...e };
      this.showEditor = true;
    },

    deleteCollection(e) {
      API.post('/viewpoint/delete', { user: this.uuid, uuid: e.uuid }).then(
        () => {
          this.getData();
        }
      );
    },

    getData() {
      console.log('GET DATA!');
      API.post('/viewpoint/collections', { user: this.uuid }).then(r => {
        console.log('reply: ', r.data);
        this.cards = r.data.data;
      });
    },

    processUpload() {
      this.rec = {};
      this.$refs.uploadIconicImage.reset();
    },

    uploadImage() {
      this.$refs.uploadIconicImage.pickFiles();
    },

    create_or_edit() {
      let vm = this;
      API.post(`/viewpoint/${this.mode}`, {
        rec: this.rec,
        user: this.uuid
      }).then(r => {
        console.log('resp: ', r.data);
        this.rec = { ...r.data.data };
        vm.$refs.uploadIconicImage.upload();
        this.showEditor = false;
        this.getData();
      });
    },

    getHeaders() {
      return [
        // these are needed in the API to identify the user
        { name: 'X-Session', value: this.$store.getters['user/session'] },
        { name: 'X-Process', value: 'iconicimage' },
        { name: 'X-Member', value: this.uuid },
        { name: 'X-Node', value: this.rec.uuid }
      ];
    }
  },

  data() {
    return {
      mode: 'create',
      showEditor: false,
      cards: [],
      rec: {
        uuid: '',
        title: '',
        subtitle: '',
        introduction: '',
        description: '',
        acknowledgements: ''
      }
    };
  }
};
</script>
